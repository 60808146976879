<template>
    <div class="bg-custom_domain-50 rounded-2xl drop-shadow-lg p-4 flex flex-col gap-3 w-fit place-items-center">
        <h2>{{ item.reference }}</h2>
        <button class="rounded-full bg-custom_domain-700 text-light py-2 px-3" @click="addToCart">{{$t("Add to cart")}}</button>
    </div>
</template>

<script>
import OrderService from '@ivy/order-service'

export default {
    name: 'ItemThumbnail',
    props: {
        item: {
            required: true
        }
    },
    methods: {
        addToCart() {
            OrderService.addToCart(this.item.reference, 1)
                .then(() => {
                    window.dispatchEvent(new Event('request__order--update'))
                })
        }
    }
}
</script>