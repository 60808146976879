<template>
  <h1 class="mb-4 text-4xl font-bold leading-none tracking-tight text-custom_organization-900">{{$t("Articles")}}</h1>
  <div class="flex flex-wrap gap-5">
    <ItemThumbnail v-for="item in items" v-bind:key="item.reference" :item="item"></ItemThumbnail>
  </div>
</template>

<script>
import ItemsService from '@ivy/items-service'
import ItemThumbnail from './components/ItemThumbnail.vue'

export default {
  name: 'App',
  components: {
    ItemThumbnail
},
  data() {
    return {
      items: []
    }
  },
  mounted() {
    ItemsService.list(14).then(response => {
      this.items = response.data
    })
  }
}
</script>

<style></style>
